
<template>
  <div>
    <v-container>
      <v-layout pa-0>
        <v-flex list-scroll sm12 style="height: 100%">
          <div class="content-text-block overflow font-sys">
            <h1 style="padding: 20px">Termo de anuência.</h1>
            <div class="pa-4 content-terms">
              <p class="mb-0">
                Eu,
                <strong>{{ this.person.name }}</strong>
              </p>
              <p v-if="this.person.cnpj" class="mb-0">
                AUTORIZO, que a doação especificada abaixo seja feita em nome da Razão Social e CNPJ:
                <strong>{{ this.person.socialReason }} - {{ formatterHelper.formatCnpj(this.person.cnpj) }}</strong>
              </p>
              <p v-else class="mb-0">
                CPF n°
                {{
                  this.person ? formatterHelper.formatCpf(this.person.cpf) : ""
                }}
                AUTORIZO,
                <template v-if="isPayroll()"
                  >de forma voluntária e irretratável,</template
                >
                a doação especificada abaixo
              </p>
              <BorderSeparation class="no-margin-2" />
              <div class="terms-itens">
                <p>Valor da doação:</p>
                <p v-if="consentTerm.parcelament > 1">
                  R$ {{  this.consentTerm.parcelamentTotalValue }} ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(this.consentTerm.parcelamentTotalValue)
                    )
                  }})
                </p>
                <p v-else>
                  R$ {{ this.consentTerm.totalValue }} ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(this.consentTerm.totalValue)
                    )
                  }})
                </p>
              </div>
              <div class="terms-itens" v-if="consentTerm.parcelament > 1">
                <p>Parcelamento</p>
                <p>{{consentTerm.parcelament}}x de R$ {{formatterHelper.formatMoney(consentTerm.totalValue + this.consentTerm.taxValue)}} ({{
                  formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(consentTerm.totalValue + this.consentTerm.taxValue)
                    )}})</p>
              </div>
              <div v-if="this.consentTerm.taxValue > 0" class="terms-itens">
                <p>
                  Taxa do Meio de Pagamento
                </p>
                <p>
                  R$ {{ this.consentTerm.taxValue }} ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(this.consentTerm.taxValue)
                    )
                  }})
                </p>
              </div>
              <div v-if="this.consentTerm.taxValue > 0 && consentTerm.parcelament > 1" class="terms-itens">
                <p>Taxa Total do Meio de pagamento, por parcela:</p>
                <p>
                  R$ {{ formatterHelper.formatMoney(this.consentTerm.parcelamentTaxValue) }} ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(this.consentTerm.parcelamentTaxValue)
                    )
                  }})
                </p>
              </div>
              <div class="terms-itens">
                <p>Total a ser Pago (doação + taxa):</p>
                <p v-if="consentTerm.parcelament > 1">
                  R$
                  {{
                    formatterHelper.formatMoney(
                      this.consentTerm.parcelamentTotalValue + (!this.isCreditCard ? this.consentTerm.parcelamentTaxValue: this.consentTerm.taxValue)
                    )
                  }}
                  ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(
                        this.consentTerm.parcelamentTotalValue + (!this.isCreditCard ? this.consentTerm.parcelamentTaxValue: this.consentTerm.taxValue)
                      )
                    )
                  }})
                </p>
                <p v-else>
                  R$
                  {{
                    this.consentTerm.totalValue + this.consentTerm.taxValue
                  }}
                  ({{
                    formatterHelper.writeNumbersAsText(
                      formatterHelper.formatMoney(
                        this.consentTerm.totalValue + this.consentTerm.taxValue
                      )
                    )
                  }})
                </p>
              </div>
              <div class="terms-itens">
                <p>Forma de Pagamento:</p>
                <p>{{ getRecurrenceName() }}</p>
              </div>
              <div class="terms-itens">
                <p>Meio de pagamento:</p>
                <p>{{ this.consentTerm.paymentMethodName }}</p>
              </div>
              <div class="terms-itens">
                <p>Campanha:</p>
                <p>{{ this.consentTerm.campaignName }}</p>
              </div>
              <div class="terms-itens">
                <p>Responsável pela campanha:</p>
                <p>{{ this.consentTerm.entityName }}</p>
              </div>
              <div class="terms-itens">
                <p>CNPJ:</p>
                <p>
                  {{
                    formatterHelper.formatCnpj(this.consentTerm.cnpjResponsible)
                  }}
                </p>
              </div>
              <div class="terms-itens mt-5">
                <p>Resumo</p>
                <p>Valor da doação</p>
              </div>
              <template v-for="item in getCartItems()">
                <div
                  class="terms-itens"
                  :key="item.id"
                  v-if="item.project.projectTypeId != ProjectTypes.Ticket"
                >
                  <p>
                    <strong>{{ item.project.shortName }}</strong>
                  </p>
                  <p>
                    <strong
                      >R$
                      {{ formatterHelper.formatMoney(item.itemValue) }}</strong
                    >
                  </p>
                </div>
                <div class="terms-itens" :key="item.id" v-else>
                  <p>
                    <strong>{{ item.donationProduct.name }}</strong>
                  </p>
                  <p>
                    <strong
                      >{{ item.donationProduct.currentQuantity }}x
                      {{
                        item.donationProduct.openValue
                          ? item.itemValue
                          : item.donationProduct.fixedValue | FormatMoney
                      }}</strong
                    >
                  </p>
                </div>
              </template>
            </div>
            <v-card-actions
              class="pa-0 mt-4"
              style="height: auto; display: flex"
            >
              <template class="mt-3">
                <div
                  disabled="true"
                  style="resize: none; width: 100%"
                  rows="4"
                  class="pa-2"
                >
                  <p v-html="generalText()"></p>
                </div>
              </template>
            </v-card-actions>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script type="plain/text">
import CartService from "@/scripts/services/cart.service.js";
import PersonService from "@/scripts/services/person.service.js";
import Cart from "@/scripts/models/cart.model";
import Person from "@/scripts/models/person.model";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import ConsentTerm from "@/scripts/models/consentTerm.model";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
export default {
  data() {
    return {
      cartService: new CartService(),
      personService: new PersonService(),
      cart: new Cart(),
      person: new Person(),
      formatterHelper: new FormatterHelper(),
      consentTerm: new ConsentTerm(),
      ProjectTypes: ProjectTypes,
      PaymentMethod: PaymentMethod,
      creditCardText: `<p>DECLARO que minha doação é voluntária.</p> 
                      <p>Declaro ciência que para as transações de CARTÃO DE CRÉDITO, considerando principalmente sua finalidade social, não estará passível para pedido de “CHARGEBACK” junto a operadora / emissora do cartão de crédito utilizado. Declaro ainda ser responsável pelo cartão de crédito utilizado e estar ciente de que essa operação, pela sua natureza não está passível de estorno ou devolução após o seu processamento, salvo em ocorrências fraudulentas, devidamente comprovadas.</p>`,
      payrollText: `<p>DECLARO estar ciente que no pagamento via "Desconto em folha" com ou sem parcelamento:</p>
                    <p>- O referido valor não ultrapassa o limite de 30% do total de deduções do meu holerite;</p>
                    <p>- No caso de doações recorrentes, o cancelamento pode ser realizado a qualquer momento;</p>
                    <p>- No caso de desligamento ou pedido de demissão antes da efetivação dos descontos, o valor da doação será descontado integralmente na rescisão do contrato de trabalho;</p>
                    <p></p>
                    <p>DECLARO ainda estar ciente, que para as DOAÇÕES VIA INCENTIVO FISCAL, que as expectativas de dedução/restituição de Imposto de Renda decorrentes das doações ora efetivadas são de minha exclusiva responsabilidade.<p>
                    <p>- Das regras da legislação que regem essa modalidade de doação (destinação do IR – específico para optantes da declaração do Imposto de Renda no modelo completo) e as compensações que posso realizar na minha declaração de ajuste anual do Imposto de Renda (pessoa física);<p>
                    <p>- Que devo guardar o comprovante de pagamento/doação por no mínimo 5 anos para apresentação em eventual fiscalização no Imposto de Renda pessoa física</p>
`,
      accountDebitText: `DECLARO que minha doação é voluntária. Autorizo o lançamento especificado acima em débito automático autorizado no domicílio bancário informado. Estou ciente que ao optar por débito automático autorizado é necessário entrar em contato com um dos canais eletrônicos meu banco e liberar o débito da sua doação.
                          Obs - Acesse o internet banking do seu banco, busque por "Débito Automático", procure o lançamento de sua doação e faça a autorização. Esse processo é realizado apenas uma vez.`,
      defaultText: "<p>DECLARO que minha doação é voluntária.</p>",
    };
  },
  created() {
    var cartId = window.location.href.split("?")[1];
    this.cartService.getConsentTermByCartId(cartId).then(
      function (data) {
        this.consentTerm = data;
        this.person = data.person;
      }.bind(this)
    );
  },
  methods: {
    getRecurrenceName(){
      if (this.consentTerm.recurrenceName == "única" && this.consentTerm.parcelament > 1) {
        return "Parcelado"
      }
      if (this.consentTerm.recurrenceName == "única") {
        return "À vista"
      }
      if(this.consentTerm.paymentMethodName == "Débito em folha")
        return this.consentTerm.recurrenceName;
      return "Recorrente";
    },
    getCartItems() {
      if (this.consentTerm.cartItem) return this.consentTerm.cartItem;
      return [];
    },
    isPayroll() {
      if (
        this.consentTerm &&
        this.consentTerm.paymentMethodName === "Débito em folha"
      ) {
        return true;
      }
      return false;
    },
    isCreditCard() {
      if (
        this.consentTerm &&
        this.consentTerm.paymentMethodName === "Cartão de crédito"
      ) {
        return true;
      }
      return false;
    },
    isAccountDebit() {
      if (
        this.cart &&
        this.cart.paymentMethodId == this.PaymentMethod.AccountDebit
      ) {
        return true;
      }
      return false;
    },
    hasTickets() {
      var items = this.getCartItems();
      for (let i = 0; i < items.length; i++) {
        if (items[i].project.projectTypeId == ProjectTypes.Ticket) return true;
      }
      return false;
    },
    generalText() {
      var text = "";
      if (this.consentTerm.term) {
        text = this.consentTerm.term + "<br>";
      } else {
        if (this.isCreditCard()) {
          text = this.creditCardText;
        } else if (this.isPayroll()) {
          text = this.payrollText;
        } else if (this.isAccountDebit()) {
          text = this.accountDebitText;
        } else {
          text = this.defaultText;
        }
      }

      if (this.hasTickets()) {
        var items = this.getCartItems();
        items.forEach((item, index) => {
          if (item.project.projectTypeId == ProjectTypes.Ticket) {
            text +=
              "REGRAS E CONDIÇÕES (" +
              item.donationProduct.name +
              ")<br />" +
              item.donationProduct.rulesAndConditions;
            if (index != items.length - 1) text += "<br /><br />";
          }
        });
      }

      return text;
    },
  },
};
</script>