import Person from "@/scripts/models/person.model";

export default class ConsentTerm {
    constructor() {
        this.person = new Person();
        this.totalValue = 0.0;
        this.taxValue = 0;
        this.recurrenceName = "";
        this.paymentMethodName = "";
        this.campaignName = "";
        this.entityName = "";
        this.cnpjResponsible = 0;
        this.cartItems = [];
    }
}