var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-layout',{attrs:{"pa-0":""}},[_c('v-flex',{staticStyle:{"height":"100%"},attrs:{"list-scroll":"","sm12":""}},[_c('div',{staticClass:"content-text-block overflow font-sys"},[_c('h1',{staticStyle:{"padding":"20px"}},[_vm._v("Termo de anuência.")]),_vm._v(" "),_c('div',{staticClass:"pa-4 content-terms"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n              Eu,\n              "),_c('strong',[_vm._v(_vm._s(this.person.name))])]),_vm._v(" "),(this.person.cnpj)?_c('p',{staticClass:"mb-0"},[_vm._v("\n              AUTORIZO, que a doação especificada abaixo seja feita em nome da Razão Social e CNPJ:\n              "),_c('strong',[_vm._v(_vm._s(this.person.socialReason)+" - "+_vm._s(_vm.formatterHelper.formatCnpj(this.person.cnpj)))])]):_c('p',{staticClass:"mb-0"},[_vm._v("\n              CPF n°\n              "+_vm._s(this.person ? _vm.formatterHelper.formatCpf(this.person.cpf) : "")+"\n              AUTORIZO,\n              "),(_vm.isPayroll())?[_vm._v("de forma voluntária e irretratável,")]:_vm._e(),_vm._v("\n              a doação especificada abaixo\n            ")],2),_vm._v(" "),_c('BorderSeparation',{staticClass:"no-margin-2"}),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Valor da doação:")]),_vm._v(" "),(_vm.consentTerm.parcelament > 1)?_c('p',[_vm._v("\n                R$ "+_vm._s(this.consentTerm.parcelamentTotalValue)+" ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(this.consentTerm.parcelamentTotalValue)
                  ))+")\n              ")]):_c('p',[_vm._v("\n                R$ "+_vm._s(this.consentTerm.totalValue)+" ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(this.consentTerm.totalValue)
                  ))+")\n              ")])]),_vm._v(" "),(_vm.consentTerm.parcelament > 1)?_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Parcelamento")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.consentTerm.parcelament)+"x de R$ "+_vm._s(_vm.formatterHelper.formatMoney(_vm.consentTerm.totalValue + this.consentTerm.taxValue))+" ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(_vm.consentTerm.totalValue + this.consentTerm.taxValue)
                  ))+")")])]):_vm._e(),_vm._v(" "),(this.consentTerm.taxValue > 0)?_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("\n                Taxa do Meio de Pagamento\n              ")]),_vm._v(" "),_c('p',[_vm._v("\n                R$ "+_vm._s(this.consentTerm.taxValue)+" ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(this.consentTerm.taxValue)
                  ))+")\n              ")])]):_vm._e(),_vm._v(" "),(this.consentTerm.taxValue > 0 && _vm.consentTerm.parcelament > 1)?_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Taxa Total do Meio de pagamento, por parcela:")]),_vm._v(" "),_c('p',[_vm._v("\n                R$ "+_vm._s(_vm.formatterHelper.formatMoney(this.consentTerm.parcelamentTaxValue))+" ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(this.consentTerm.parcelamentTaxValue)
                  ))+")\n              ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Total a ser Pago (doação + taxa):")]),_vm._v(" "),(_vm.consentTerm.parcelament > 1)?_c('p',[_vm._v("\n                R$\n                "+_vm._s(_vm.formatterHelper.formatMoney(
                    this.consentTerm.parcelamentTotalValue + (!this.isCreditCard ? this.consentTerm.parcelamentTaxValue: this.consentTerm.taxValue)
                  ))+"\n                ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(
                      this.consentTerm.parcelamentTotalValue + (!this.isCreditCard ? this.consentTerm.parcelamentTaxValue: this.consentTerm.taxValue)
                    )
                  ))+")\n              ")]):_c('p',[_vm._v("\n                R$\n                "+_vm._s(this.consentTerm.totalValue + this.consentTerm.taxValue)+"\n                ("+_vm._s(_vm.formatterHelper.writeNumbersAsText(
                    _vm.formatterHelper.formatMoney(
                      this.consentTerm.totalValue + this.consentTerm.taxValue
                    )
                  ))+")\n              ")])]),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Forma de Pagamento:")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.getRecurrenceName()))])]),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Meio de pagamento:")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(this.consentTerm.paymentMethodName))])]),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Campanha:")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(this.consentTerm.campaignName))])]),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("Responsável pela campanha:")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(this.consentTerm.entityName))])]),_vm._v(" "),_c('div',{staticClass:"terms-itens"},[_c('p',[_vm._v("CNPJ:")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.formatterHelper.formatCnpj(this.consentTerm.cnpjResponsible))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"terms-itens mt-5"},[_c('p',[_vm._v("Resumo")]),_vm._v(" "),_c('p',[_vm._v("Valor da doação")])]),_vm._v(" "),_vm._l((_vm.getCartItems()),function(item){return [(item.project.projectTypeId != _vm.ProjectTypes.Ticket)?_c('div',{key:item.id,staticClass:"terms-itens"},[_c('p',[_c('strong',[_vm._v(_vm._s(item.project.shortName))])]),_vm._v(" "),_c('p',[_c('strong',[_vm._v("R$\n                    "+_vm._s(_vm.formatterHelper.formatMoney(item.itemValue)))])])]):_c('div',{key:item.id,staticClass:"terms-itens"},[_c('p',[_c('strong',[_vm._v(_vm._s(item.donationProduct.name))])]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(item.donationProduct.currentQuantity)+"x\n                    "+_vm._s(_vm._f("FormatMoney")(item.donationProduct.openValue
                        ? item.itemValue
                        : item.donationProduct.fixedValue)))])])])]})],2),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-0 mt-4",staticStyle:{"height":"auto","display":"flex"}},[[_c('div',{staticClass:"pa-2",staticStyle:{"resize":"none","width":"100%"},attrs:{"disabled":"true","rows":"4"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.generalText())}})])]],2)],1)])],1)],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }